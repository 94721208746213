import "./expPage.css";
import "./experience.css";
import { Grid } from "@mui/material";
import SubExperience from "./subExperience";
import { useState } from "react";

const ExperienceMultiple = (prop) => {
    const jobInfo = prop.jobInfo;
    const [subState, setSubState] = useState(false);
    const handleSubState = () => {
        setSubState(!subState);
    };
    return (
        <div style={{ marginBottom: "30px" }} onClick={handleSubState}>
            <div className="multiJobs">
                {/* Cascading block to show multiple or to signal hover */}
                <Grid
                    container
                    className="job drop-shadow cascade"
                    spacing={{ xs: 2 }}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        marginLeft: "0",
                        marginBottom: "0px",
                        position: "absolute",
                        zIndex: "0",
                    }}
                />

                <Grid
                    container
                    className="job drop-shadow"
                    spacing={{ xs: 2 }}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignContent: "center",
                        marginLeft: "0",
                        marginBottom: "30px",
                        position: "relative",
                        zIndex: "1",
                    }}
                >
                    <Grid
                        xs={12}
                        md={3}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <img
                            className="job-logo"
                            src={require(`../../assets/${jobInfo.logo}`)}
                            alt="Logo"
                        />
                    </Grid>

                    <Grid xs={12} md={9} style={{ padding: "8px" }}>
                        <div style={{ marginLeft: "20px" }}>
                            <h2 className="job-title">{jobInfo.company}</h2>
                            <h3 className="job-position">{jobInfo.position}</h3>
                            <div className="job-date">{jobInfo.date}</div>
                            <div className="job-location">
                                {jobInfo.location}
                            </div>
                            <ul className="job-detail-list">
                                {jobInfo.details.map((detail) => {
                                    return <li>{detail}</li>;
                                })}
                            </ul>
                        </div>
                    </Grid>
                </Grid>
            </div>
            <div
                container
                className="sub-experience-container"
                style={{
                    flexDirection: "column",
                    alignItems: "center",
                    visibility: subState ? "visible" : "hidden",
                    maxHeight: subState ? "100%" : "0",
                }}
            >
                {jobInfo.subRoles.map((jobData) => {
                    return <SubExperience jobInfo={jobData}></SubExperience>;
                })}
            </div>
        </div>
    );
};

export default ExperienceMultiple;
