const projectDatalList = [
    {
        logo: "Terminal.png",
        title: "SolanaBot",
        url: "https://x.com/solana_bot",
        details: [
            "A software automating the sending of blockchain transactions to acquire NFTs on the Solana blockchain.",
            "Licensed and purchased by 40+ paid users, has sent over 1750+ successful Solana blockchain transactions.",
            "Designed and implemented a SQL database to map user, wallets, licenses, subscription information.",
            "Deployed a React.js site to allow users to purchase SolanaBot through a chrome wallet.",
        ],
    },
    {
        logo: "Bybit-Terminal.png",
        title: "Bybit Automation",
        url: "https://github.com/RockyLogic/Bybit-Automation",
        details: [
            "A convient CLI to leverage the Bybit API. Subscribe to order books via websocket. Send limit & market orders directly through the CLI, fetch account data, positions, and trade history. Built with Typescript, Node.js, and Bybit API.",
        ],
    },
    {
        logo: "TMUPrep.png",
        title: "TMUPrep",
        url: "https://github.com/RockyLogic/TMUPrep",
        details: [
            "TMUPrep is a handy app that helps both incoming and current TMU students make the most of their semesters. With course details for every single course on the university site allowing CS students to plan out semesters ahead, and check if they're schedule satisfy requirements. Built with Golang, Gin, React.js, Firebase, and MongoDB.",
        ],
    },
    {
        logo: "TorontoCypress.png",
        title: "Toronto Cypress",
        url: "https://github.com/RockyLogic/Cypress",
        details: [
            "Enable local residents to report and share incidents such as utility failures, obstructions, and other intrusions. Built with React.js, Redux.js, Google Cloud GCP, Firebase authentication and Firestore cloud database.",
        ],
    },
    {
        logo: "TurretShooter.png",
        title: "Turret Shooter",
        url: "https://github.com/RockyLogic/Turret-Shooter",
        details: [
            "Game created with C++ and OpenGL. The game is a 3D turret shooter where the player must shoot down enemy planes before they reach the player. The game features a 3D environment, collision detection, and a scoring system.",
        ],
    },
    {
        logo: "Stats-Purple.png",
        title: "Stashed.io",
        url: "https://github.com/RockyLogic/Stashed.Inventory",
        details: [
            "Engineered a web app to track and analyze the inventory and sales of a business. OAuth2 users verified accounts, linked to a MongoDB database. Displayed modelled user sales data graphically.",
        ],
    },
    {
        logo: "AmzGen.jpg",
        title: "AMZ Gen",
        url: "https://x.com/AMZgen",
        details: [
            "A CLI tool automating account creation and reinstatement for Amazon and Outlook.",
            "Used and purchased by over 75+ users and has logged and generated over 3000+ new accounts.",
            "Obfuscated code & packaged software into an executable to be distributed to users for patches and updates.",
        ],
    },
    {
        logo: "Discord.jpg",
        title: "Discord Automation",
        url: "https://github.com/RockyLogic/Discord-OCR-Bot",
        details: [
            "Automation of information: Synced program to login to 10+ discord accounts pulling information of different servers, aggregated all information into a single server at a low lancency.",
            "OCR: Bot identifying text within pictures, and outputting the text as human readable format",
            "Gifts: Regex messages from socket pattern match gifts urls, redeemed through reversed engineered API call.",
            "Invitations: Parsed messages from socket for limited invites, automate joining servers.",
        ],
    },
    {
        logo: "github.jpg",
        title: "More Projects On Github",
        url: "https://github.com/RockyLogic",
        details: [
            "More projects can be found on my Github profile. Projects include a variety of languages and frameworks.",
            "More Projects Coming Soon!",
        ],
    },
];

export default projectDatalList;
