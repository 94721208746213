import { Grid } from "@mui/material";
import "./project.css";

const Project = (props) => {
    const project = props.project;
    return (
        <a
            className="porject-urls"
            target="_blank"
            href={project.url}
            rel="noreferrer"
        >
            <Grid
                container
                className="project"
                spacing={{ xs: 2 }}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginLeft: "0",
                }}
            >
                <Grid
                    xs={12}
                    md={6}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "0px",
                    }}
                >
                    <img
                        src={require(`../../assets/${project.logo}`)}
                        alt="Logo"
                        className="project-logo"
                    />
                </Grid>

                <Grid item xs={12} md={6}>
                    <h2 className="job-title">{project.title}</h2>
                    {project.details.map((detail) => {
                        return <div className="project-detail">{detail}</div>;
                    })}
                </Grid>
            </Grid>
        </a>
    );
};

export default Project;
