import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import "./nav.css";

const pages = [
    { name: "Home", link: "#home" },
    { name: "Experience", link: "#experience" },
    { name: "Projects", link: "#projects" },
    { name: "Resume", link: "https://www.linkedin.com/in/~rocky/" },
];

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
        null
    );

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <AppBar
            position="absolute"
            sx={{
                backgroundColor: "#FFFFFF",
                color: "#292929",
                boxShadow: 0,
            }}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/* Regular Display Navbar */}
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: {
                                xs: "none",
                                md: "flex",
                            },
                            justifyContent: "center",
                        }}
                    >
                        {pages.map((page) => (
                            <Button
                                className="navButton"
                                key={page.name}
                                onClick={handleCloseNavMenu}
                                href={page.link.toLowerCase()}
                                target={
                                    page.name === "Resume" ? "_blank" : "_self"
                                }
                                sx={{
                                    my: 0,
                                    color: "#585858",
                                    display: "block",
                                    fontSize: 16,
                                    border: "3px solid transparent",
                                    marginX: "10px",
                                    fontWeight: "bold",

                                    "&:hover": {
                                        borderBottom: "3px solid #47bb73",
                                        backgroundColor: "#f6f6f6",
                                    },
                                }}
                            >
                                {page.name}
                            </Button>
                        ))}
                    </Box>

                    {/* Collapsed Navbar */}
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "flex", md: "none" },
                            justifyContent: "right",
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>

                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "right",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                                "& .MuiPaper-root": {
                                    backgroundColor: "#292929",
                                    border: "3px solid #585858",
                                },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem
                                    key={page.name}
                                    onClick={handleCloseNavMenu}
                                    LinkComponent={"a"}
                                    href={page.link.toLowerCase()}
                                    sx={{
                                        my: 0,
                                        color: "#FFFFFF",
                                        display: "block",
                                        fontSize: 16,
                                        border: "3px solid transparent",
                                        marginX: "15px",
                                        backgroundColor: "#292929",

                                        "&:hover": {
                                            borderBottom: "3px solid #47BB73",
                                            backgroundColor: "#292929",
                                        },
                                    }}
                                >
                                    <a
                                        href={page.link.toLowerCase()}
                                        style={{
                                            textDecoration: "none",
                                            color: "inherit",
                                        }}
                                    >
                                        <Typography textAlign="center">
                                            {page.name}
                                        </Typography>
                                    </a>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
export default ResponsiveAppBar;
