const jobDataList = [
    {
        logo: "TDBank.png",
        company: "TD Securities",
        position: "Software Engineer Intern",
        date: "May 2021 - Aug 2023 (1 Year & 4 Months)",
        location: "Toronto, ON, Canada",
        multipleRoles: true,
        details: [
            "Teams: Virtual Corporate Accounts, Transparency Trader, FX eTrading",
            // "Working on software behind institutional foreign exchange, a low latency trading platform with Java, React, and Docker.",
        ],
        subRoles: [
            {
                logo: "TDBank.png",
                company: "TD Securities",
                position: "Software Engineer Intern",
                date: "May 2023 - Aug 2023 (4 Months)",
                location: "Toronto, ON, Canada",
                team: "FX eTrading",
                details: [
                    "Working on software behind institutional foreign exchange, a low latency trading platform with Java, React, and Docker.",
                ],
            },
            {
                logo: "TDBank.png",
                company: "TD Securities",
                position: "Software Engineer Intern",
                date: "Jan 2023 - May 2023 (4 Months)",
                location: "Toronto, ON, Canada",
                team: "Transparency Trader",
                details: [
                    "Intraday risk and P&L platform built with React and Java servicing front office trading desks for real-time position management.",
                ],
            },
            {
                logo: "TDBank.png",
                company: "TD Securities",
                position: "Software Engineer Intern",
                date: "May 2021 - Dec 2022 (8 Months)",
                location: "Toronto, ON, Canada",
                team: "Virtual Corporate Accounts",
                details: [
                    "Designed and built ISO transaction microservice to fetch virtual account transaction histories using Java, Spring Boot, and IBM MQ.",
                ],
            },
        ],
    },
    {
        logo: "Krates.png",
        company: "Krates",
        position: "Software Engineer Contract",
        date: "Oct 2021 - Feb 2022 (5 Months)",
        location: "Harrisburg, PA, USA",
        multipleRoles: false,
        details: [
            // "Integrated OAuth2 into Krates’ shipping services platform, allowing Google sign-in on top of the existing user/pass.",
            // "Implemented Stripe payment processing. Built, and maintained 100% of the order fulfillment flow.",
            // "Collaborated with UI/UX team brought Figma mocks to life in Vue.js.",
            "Shipping SAAS Platform, Firebase OAuth2 Integration, Stripe Payment Processing, Frontend with Vue.js",
        ],
    },
    {
        logo: "BlackBerry.jpeg",
        company: "BlackBerry",
        position: "Software Engineer Intern",
        date: "Aug 2021 - Dec 2021 (4 Months)",
        location: "Ottawa, ON, Canada",
        multipleRoles: false,
        details: [
            "Enterprise Identity Team",
            "Improved security and reliability of Enterprise Identity systems by engineering patches to resolve security issues with Java/JavaScript.",
        ],
    },
    {
        logo: "GCTO.jpeg",
        company: "Global Creative Technology",
        position: "Software Engineer Intern",
        date: "May 2021 - August 2021 (4 Months)",
        location: "Waterloo, ON",
        multipleRoles: false,
        details: [
            // "Kickstarted a MVP securing $60K in funding with 3 engineers using Typescript, Express.js, and Vue.js",
            // "Facilitated secure transactions by developing a smart contract for the marketplace and a MetaMask OAuth2 flow.",
            // "Streamlined the checkout process by automating order fulfillment on validating Stripe payment webhook.",
            // "Automated the CI/CD process for UI, end-to-end tests, and deployment to Google Cloud with Jest, and Selenium.",
            "Kickstarted a startup securing $60K in funding with 3 engineers built MVPs using Typescript, Express.js, and Vue.js",
            // "Facilitated secure transactions by developing a smart contract for the marketplace and a MetaMask OAuth2 flow.",
        ],
    },
];

export default jobDataList;
