import "./expPage.css";
import "./experience.css";
import { Grid } from "@mui/material";

const SubExperience = (prop) => {
    const jobInfo = prop.jobInfo;
    return (
        <Grid
            container
            className="subJob drop-shadow"
            spacing={{ xs: 2 }}
            style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                marginLeft: "0px",
                overflow: "hidden",
            }}
        >
            <Grid style={{}}>
                <div>
                    <h5 className="job-position">{jobInfo.team}</h5>
                    <div className="job-date">{jobInfo.position}</div>
                    <div className="job-date">{jobInfo.date}</div>
                    <ul className="job-detail-list">
                        {jobInfo.details.map((detail) => {
                            return <li>{detail}</li>;
                        })}
                    </ul>
                </div>
            </Grid>
        </Grid>
    );
};

export default SubExperience;
