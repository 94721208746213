import { Container } from "@mui/system";
import Project from "./project";
import "./projectPage.css";
import projectDatalList from "../data/projectData"

const ProjectPage = () => {
    return (
        <Container
            id="projects"
            className="project-page"
            style={{
                display: "flex",
                justifyContent: "center",
            }}
        >
            <h1 style={{ marginBottom: "10vh", marginTop: "10vh" }}>
                Projects
            </h1>
            {projectDatalList.map((project) => {
                return <Project project={project}></Project>;
            })}
        </Container>
    );
};

export default ProjectPage;
