import { Container } from "@mui/system";
import Experience from "./experience.jsx";
import "./expPage.css";
import jobDataList from "../data/experienceData";
import ExperienceMultiple from "./experienceMultiple.jsx";

const ExperiencePage = () => {
    return (
        <Container
            id="experience"
            className="exp-page"
            style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "100px",
            }}
        >
            <h1
                style={{
                    marginBottom: "10vh",
                    marginTop: "10vh",
                }}
            >
                Experience
            </h1>

            {jobDataList.map((jobData) => {
                if (jobData.multipleRoles) {
                    return (
                        <ExperienceMultiple
                            jobInfo={jobData}
                        ></ExperienceMultiple>
                    );
                } else {
                    return <Experience jobInfo={jobData}></Experience>;
                }
            })}
        </Container>
    );
};

export default ExperiencePage;
