import "./expPage.css";
import "./experience.css";
import { Grid } from "@mui/material";

const Experience = (prop) => {
    const jobInfo = prop.jobInfo;
    return (
        <Grid
            container
            className="job drop-shadow"
            spacing={{ xs: 2 }}
            style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                marginLeft: "0",
            }}
        >
            <Grid
                xs={12}
                md={3}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <img
                    className="job-logo"
                    src={require(`../../assets/${jobInfo.logo}`)}
                    alt="Logo"
                />
            </Grid>

            <Grid xs={12} md={9} style={{ padding: "8px" }}>
                <div style={{ marginLeft: "20px" }}>
                    <h2 className="job-title">{jobInfo.company}</h2>
                    <h3 className="job-position">{jobInfo.position}</h3>
                    <div className="job-date">{jobInfo.date}</div>
                    <div className="job-location">{jobInfo.location}</div>
                    <ul className="job-detail-list">
                        {jobInfo.details.map((detail) => {
                            return <li>{detail}</li>;
                        })}
                    </ul>
                </div>
            </Grid>
        </Grid>
    );
};

export default Experience;
